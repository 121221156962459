import React, { useEffect,useState } from 'react';
import { Button, Form, Input, Alert } from "antd";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { motion } from "framer-motion";
import API from '../../../api'
import { connect } from "react-redux"
import { Auth,hideAuthMessage } from "../../../actions/Auth"
import Remember_d from "../../../images/new/check_box_d.svg"
import Remember_s from "../../../images/new/check_box_s.svg"

export const LoginForm = props => {
	const [check,setCheck]=useState(false)
	// let history = useHistory();
const {login}=API;
	const { 
		otherSignIn, 
		showForgetPassword, 
		hideAuthMessage,
		onForgetPasswordClick,
		showLoading,
		signInWithGoogle,
		signInWithFacebook,
		extra, 
		signIn, 
		token, 
		loading,
		redirect,
		showMessage,
		message,
		allowRedirect
	} = props

	const initialCredential = {
		email: '',
		password: ''
	}
	

	const onLogin = values => {
		// values['remember']=check
		props.Auth(values)
	};
	useEffect(() => {
		if (token !== null && allowRedirect) {
		}
		if(showMessage) {
			setTimeout(() => {
				props.hideAuthMessage();
			}, 2500);
		}
	});
	
	const changeChecked=()=>{
		setCheck(!check)
	}

	return (
		<div className='wash_auth_right_form'>
			<div className='wash_auth_right_form_head1'>Account Login</div>
			<div className='wash_auth_right_form_head2'>Enter your login details to continue</div>
			<Form 
				layout="vertical" 
				name="login-form" 
				initialValues={initialCredential}
				onFinish={(e)=>onLogin(e)}
			>
				<Form.Item 
				    style={{marginBottom:"8px"}}
					name="email" 
					label="User id" 
					rules={[
						{ 
							required: true,
							message: 'Please enter your user id',
						},
						{ 
							// type: 'email',
							message: 'PLEASE PROVIDE A VALID USER ID'
						}
					]}>
					<Input style={{ border: '1px solid #ECECEC',borderRadius:"3px" }}/>
				</Form.Item>
				<Form.Item 
				    style={{marginBottom:"8px"}}
					name="password" 
					label={
						<div className={`${showForgetPassword? 'd-flex justify-content-between w-100 align-items-center' : ''}`}>
							<span>Password</span>
							{
								showForgetPassword && 
								<span 
									onClick={() => onForgetPasswordClick} 
									className="cursor-pointer font-size-sm font-weight-normal text-muted"
								>
									Forget Password?
								</span>
							} 
						</div>
					} 
					rules={[
						{ 
							required: true,
							message: 'Please Enter your password',
						}
					]}
				>
					<Input.Password  style={{ border: '1px solid #ECECEC',borderRadius:"3px" }}/>
				</Form.Item>
				<div className='wash_login_remember'>
					<img src={check?Remember_s:Remember_d} alt="Remember me" onClick={changeChecked}/>
					<div>Remember me</div>
				</div>
				<Form.Item>
					<Button type="primary" htmlType="submit" block loading={loading} style={{background:"#38B6FF",border:"1px solid #38B6FF",borderRadius:"3px" }}>
					Login
					</Button>
				</Form.Item>
				{ extra }
				<motion.div 
				initial={{ opacity: 0, marginBottom: 0,marginTop:0 }} 
				animate={{ 
					opacity: showMessage ? 1 : 0,
					marginBottom: showMessage ? 30 : 0 ,
					marginTop: showMessage ? -30 : 0 
				}}> 
				<Alert type="error" style={{textAlign:'center',fontStyle:'italic',textTransform:'capitalize'}} showIcon message={message}></Alert>
			</motion.div>
				<div className='wash_login_mail'>In case of any issues, please contact<br/> <a href="mailto:info@washtrax.com"> info@washtrax.com</a></div>
			</Form>
		</div>
	)
}

LoginForm.propTypes = {
	otherSignIn: PropTypes.bool,
	showForgetPassword: PropTypes.bool,
	extra: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
};

LoginForm.defaultProps = {
	otherSignIn: true,
	showForgetPassword: false
};


const mapStateToProps = state => ({
	darkMode: state.data.isDarkMode,
	loading:state.auth.loading,
	token:state.auth.token,
	message:state.auth.message,
	showMessage:state.auth.showMessage
  })
  
  export default connect(mapStateToProps, { Auth,hideAuthMessage })(LoginForm)

